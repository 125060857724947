$(".selector__button").click(function() {
    $(".selector__button").not($(this)).removeClass('active');
    $(".selector__dropdown--main").not($(this).siblings()).slideUp();
    $(this).toggleClass('active');
	$(this).siblings(".selector__dropdown--main").slideToggle();
});

$(document).click(function(e) {
    var callbackPopup = $(".selector__container")
    if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
        $(".selector__button").removeClass('active');
        $(".selector__dropdown--main").slideUp();
    };
});
