import { getSystemErrorMap } from "util";
import { formRegex } from "../../_Common/const";

export default class disclosureSubscription {
    constructor() {
        console.log(
            `disclosureSubscription class is loaded`
        );
        // Form elements
        this.formMain = document.querySelector(`.jsFormMain`);
        this.formSuccess = document.querySelector(`.jsFormSuccess`);
        this.mailInput = document.querySelector(`.jsMailInput`);
        this.submitBtnNode = document.querySelector(`.jsSubmitBtn`);
        this.checkboxes = document.querySelectorAll(`.jsFormItem input[type='checkbox']`);

        this.resultSelectItems = [];
        this.responseStatusMessageNode = document.querySelector(
            `.jsResponseStatus`
        );

        if (this.mailInput) {
            this.mailInput.addEventListener(`input`, (e) => {
                this.handleMailInput(e);
            });
        }

        if (this.formMain) {
            this.formMain.addEventListener(`submit`, (e) => {
                e.preventDefault();
                this.handleSubmit();
            });
        }
        // Kinda sorta state 😜
        this.mailIsValid = false;
    }

    // 💌 💌 💌
    handleMailInput = (e) => {
        this.validateMailInput(e);
    };

    validateMailInput = (e) => {
        this.handleMailErrorUpdate(e);
        const val = e.target.value;
        if (!val.length) {
            this.hideUserMistake(e);
            this.mailInput.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.mailInput.classList.add(`jsIsEmpty`);
            this.mailIsValid = false;
        } else if (val.length > 0 && val.match(formRegex.mailValidationRegex)) {
            this.mailInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.mailInput.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.mailIsValid = true;
        } else {
            this.mailInput.classList.remove(`success`, `jsSuccess`, `jsIsEmpty`);
            this.mailInput.classList.add(`error`, `jsError`);
            this.mailIsValid = false;
        }
    };

    handleMailErrorUpdate = (e) => {
        const parent = document.querySelector(`.jsFormItem`);
        const errorP = parent.querySelector(`.jsFormItemMistake`);
        if (errorP) {
            errorP.innerText = this.handleEmailErrorMessage();
        }
        return;
    };

    // Validate form
    validateForm = () => {
        if (
            this.mailIsValid
        ) {
            return true;
        } else {
            this.hideUserMistake();
            this.createError();
            return false;
        }
    };

    createError = () => {
        const errorContainer = document.querySelector(`.jsFormMistake`);
        let errorP = document.createElement("p");
        errorP.classList.add(
            `jsFormItemMistake`
        );
        errorP.innerHTML = this.handleEmailErrorMessage();
        errorContainer.append(errorP)
    }

    checkSelectItems = arr => {
        arr.forEach(item => item.checked ? this.resultSelectItems.push(item.value) : false);
        //console.log(this.resultSelectItems)
        return this.resultSelectItems;
    }

    handleEmailErrorMessage = () => {
        const mailInput = this.mailInput.value;

        if (mailInput.length === 0) return `Заполните это поле.`;
        const marker = mailInput.indexOf(`@`);
        if (marker === -1) return `Заполните это поле.`;
        const substrings = mailInput.split(`@`);
        if (substrings[0].length === 0)
            return `Введите часть адреса до символа "@".`;
        if (substrings[1].length === 0)
            return `Введите часть адреса после символа "@".`;
        return ``;
    };

    hideUserMistake = (e) => {
        const errorP = document.querySelector(`.jsFormItemMistake`);
        errorP ? errorP.remove() : false;
    };

    handleSubmit = () => {
        if (this.validateForm()) {
            console.log(`Networking`);
            this.checkSelectItems(this.checkboxes);
            this.sendViaFetch();
        }
    };

    sendViaFetch = async () => {
        console.log(`Sending via fetch`);
        const url = "/sub_disclosure/";
        const data = {
            email: this.mailInput.value,
            topics: this.resultSelectItems
        };
        const dataString = JSON.stringify(data);
        let response = await fetch(url, {
            method: "POST",
            headers: {
                "x-requested-with": "XMLHttpRequest",
                "Content-Type": "application/json"
            },
            body: dataString,
        });

        if (response.ok) {
            console.log(`Response OK`);
            try {
                let responseJson = await response.json();
                this.handleResponse(responseJson);
            } catch (err) {
                console.error("Error parsing json from " + url + "\n" + err);
                this.showSubmitFail();
            }
        } else {
            console.error("http error while getting url: " + response.status);
            this.showSubmitFail();
        }
    };

    handleResponse = (responseJson) => {
        console.log(`Handling response`);
        if (responseJson && responseJson.errors && responseJson.errors.length) {
            let errors = Object.entries(responseJson.errors);
            errors = errors.filter(item => item[0] !== "length" ? true : false);
            errors = errors.map(item => item[1]);
            errors = errors.join("<br/>");
            this.showSubmitFail(errors);
        } else {
            this.showSubmitSuccess();
        }
    };

    showSubmitSuccess = () => {
        console.log(`Show submit success`);
        if (this.submitBtnNode) {
            $(this.formMain).hide();
            $('.disclosure-subscription__success').fadeIn();
        }
    };

    showSubmitFail = (errors = `Произошла ошибка`) => {
        console.log(`Showing submit fail`);
        if (this.responseStatusMessageNode) {
            this.responseStatusMessageNode.innerHTML = ``;
            this.responseStatusMessageNode.innerHTML = errors;
        }
    };
}

