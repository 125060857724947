const message = "app.js is connected";
console.log(message);

// // Page controlling methods
// import Page from "./modules/_Common/Page";
// window.Page = Page;

// // Page Header
// import PageHeader from "./modules/PageHeader/PageHeader";
// window.PageHeader = PageHeader;

// // Page header script for tabs
// import pageHeaderTabs from "./modules/PageHeader/pageHeaderTabs";
// window.pageHeaderTabs = pageHeaderTabs;

// // Currency tabs
import currencyTabs from "./modules/CurrencyTabsNav/CurrencyTabs";
window.currencyTabs = currencyTabs;

if (typeof chartData !== 'undefined') {
    document.addEventListener('DOMContentLoaded', function() {
        for (const currency of Object.keys(chartData)) {
            chartData[currency] = JSON.parse(entitiesDecode(chartData[currency]));
        }
        currencyTabs();
    });
}

// // NavFeatured
// import NavFeatured from "./modules/NavFeatured/NavFeatured";
// window.NavFeatured = NavFeatured;

// engineering-works
import "./modules/engineering-works/engineering-works";

// Header
import "./modules/header/header";

// Footer
import "./modules/footer/footer";

// Popups
import "./modules/popup/popup";
import "./modules/popup/popup-cookie/popup-cookie";
import "./modules/popup/popup-subscription/popup-subscription";
import {onClickReCaptcha, submitQuestionForm} from "./modules/popup/popup-question/popup-question";
window.onClickReCaptcha = onClickReCaptcha;
window.submitQuestionForm = submitQuestionForm;
import "./modules/popup/popup-message/popup-message";
//window.su
// Inner header
import "./modules/inner-header/inner-header";

// Inner header
import "./modules/with-aside/with-aside";

// About index
import "./modules/about/about-index/about-index";

// Feedback
import "./modules/feedback/feedback";

// Subscription
import disclosureSubscription from "./modules/disclosure-of-information/disclosure-subscription-page/disclosure-subscription-page";
window.disclosureSubscription = disclosureSubscription;

// Trust management page
import "./modules/products/trust-management/trust-management-page/trust-management-page";

// Closed funds page
import "./modules/products/closed-funds/closed-funds-page/closed-funds-page";

// Closed funds page
import "./modules/qa/qa-page/qa-page";

// Funds slider
import "./modules/products/funds/funds-slider/funds-slider";

// Funds slider
import "./modules/products/funds/fund/fund-description/fund-description";

// Trust management of private investors assets
import ContentviewTrustManagmentOfPrivateInvestorsAssets from "./modules/Contentview-TrustManagmentOfPrivateInvestorsAssets/Contentview-TrustManagmentOfPrivateInvestorsAssets";
window.ContentviewTrustManagmentOfPrivateInvestorsAssets = ContentviewTrustManagmentOfPrivateInvestorsAssets;

// operation-log
import "./modules/operation-log/operation-log";

// Date picker
($(".data-export")).each((i, item) => {
    if ($(item).length) {
        DatePicker.localeTexts['customKey'] = {
            titles: {
                // days
                DD: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
                // daysShort
                D: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                // months
                MMMM: [
                    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
                ],
                // monthsShort
                MMM: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек']
            },
            titleFormat: 'MMM yyyy',
            todayFormat: 'D, dd MMMM, yyyy',
            date: 'Date',
            time: 'Time',
            weekStartDay: 'Mon',
        };

        var rangePicker = DatePicker.createRangePicker({
            startpicker: {
                date: new Date(),
                input: $(item).find('.form-export__date-item-input--start')[0],
                container: $(item).find('.form-export__date-item-container--start')[0],
                weekStartDay: 'Mon',
            },
            endpicker: {
                date: new Date(),
                input: $(item).find('.form-export__date-item-input--end')[0],
                container: $(item).find('.form-export__date-item-container--end')[0],
                weekStartDay: 'Mon',
            },
            format: 'dd.MM.yyyy',
            language: 'customKey',
            selectableRanges: [
                [new Date(2002, 11, 18), new Date()]
            ]
        });
    };
})

if ($(".operation-filter").length) {
    DatePicker.localeTexts['customKey'] = {
        titles: {
            // days
            DD: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
            // daysShort
            D: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            // months
            MMMM: [
                'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
            ],
            // monthsShort
            MMM: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек']
        },
        titleFormat: 'MMM yyyy',
        todayFormat: 'D, dd MMMM, yyyy',
        date: 'Date',
        time: 'Time',
        weekStartDay: 'Mon',
    };

    const selectableRanges = [[new Date(2002, 11, 18), new Date()]];
    new DatePicker('.operation-filter__dates-container--start', {
        date: $('.operation-filter__dates-input--start').val(),
        language: 'customKey',
        input: {
            element: '.operation-filter__dates-input--start',
            format: 'dd.MM.yyyy'
        },
        selectableRanges,
        weekStartDay: 'Mon',
        type: 'date',
        usageStatistics: false
    });
    new DatePicker('.operation-filter__dates-container--end', {
        date: $('.operation-filter__dates-input--end').val(),
        language: 'customKey',
        input: {
            element: '.operation-filter__dates-input--end',
            format: 'dd.MM.yyyy'
        },
        selectableRanges,
        weekStartDay: 'Mon',
        type: 'date',
        usageStatistics: false
    });
};

// Tabs
$(".tabs__tab:not(:first-of-type)").hide();
$(".tabs__nav-btn:first-of-type").addClass("active");
$(".tabs__nav-btn").click(function() {
    if(!$(this).hasClass('active')) {
        $(this).closest(".tabs__nav").children(".tabs__nav-btn")
            .removeClass("active")
            .eq($(this).index())
            .addClass("active");
        $(this).closest(".tabs__nav").siblings(".tabs__content").children(".tabs__tab")
            .hide()
            .eq($(this).index())
            .fadeIn();
    }
});

// Selector
import "./modules/selector/selector";

// Salespoints
import salesPointsUi from "./modules/Contentview-SalesCityList/salesPointsUi";
window.salesPointsUi = salesPointsUi;
import salesCityInfoUi from "./modules/Contentview-SalesCityInfo/salesCityInfoUi";
window.salesCityInfoUi = salesCityInfoUi;

$(window).scroll(function(){
    // to top btn
    if($(this).scrollTop() > 1200){
        $('.to-top').addClass('scrolled');
    } else {
        $('.to-top').removeClass('scrolled');
    }
});

$('.to-top').click(function(){
    $('html, body').animate( { scrollTop: 0 }, 1100 );
});

// PastNames
import "./modules/past-names/past-names";

// Trust management form format tel
$.fn.formatPnoneNumber = function(){
	return this.each(function(){
		$(this).bind('keyup', function(){
			var num = this.value.replace(/^(8|7|\+7)/ , '' ).replace( /\D/g, '' ).split( /(?=.)/ ), i = num.length;
			if ( 0 <= i ) num.unshift( '+7' );
			if ( 1 <= i ) num.splice( 1, 0, '(' );
			if ( 4 <= i ) num.splice( 5, 0, ')' );
			if ( 7 <= i ) num.splice( 9, 0, '-' );
			if ( 9 <= i ) num.splice( 12, 0, '-' );
			if ( 11 <= i ) num.splice( 15, num.length - 15 );
			this.value = num.join( '' );
		});
	});
};
