import CityList from "./CityList";

const salesPointsUi = (cityList) => {
    let salesPoints = new CityList(cityList);

    // Get data
    const largeCities = salesPoints.getLargeCities();
    const allCities = salesPoints.getAllCities();
    // Large or all selected
    let currerntCityPartition = "all";
    // Cities to display on the map
    let mapCities = allCities;
    // DOM elements
    const firstLettersContainer = document.querySelector(
        "#js_lettersContainer"
    );
    const mainListContainer = document.querySelector("#js_mainListContainer");
    const largeCitiesBtn = document.querySelector("#js_largeCitiesBtn");
    const allCitiesBtn = document.querySelector("#js_allCitiesBtn");
    const mapDomElement = document.querySelector("#js_yMap");

    // Render default view setup
    salesPoints.renderFirstLetters(allCities, firstLettersContainer);
    salesPoints.renderCitiesAlphabetically(allCities, mainListContainer);
    allCitiesBtn.classList.add("is-active");
    ymaps.ready(initYandexMap);
    setLettersTargetAction();

    // Large / all switch
    largeCitiesBtn.addEventListener("click", function () {
        // UI update
        allCitiesBtn.classList.remove("is-active");
        this.classList.add("is-active");
        // Target action
        currerntCityPartition = "large";
        salesPoints.renderFirstLetters(largeCities, firstLettersContainer);
        salesPoints.renderCitiesAlphabetically(largeCities, mainListContainer);
        // Map
        clearMap();
        mapCities = largeCities;
        ymaps.ready(initYandexMap);
        // Set target action for letters
        setLettersTargetAction();
    });

    allCitiesBtn.addEventListener("click", function () {
        // UI update
        largeCitiesBtn.classList.remove("is-active");
        this.classList.add("is-active");
        // Target action
        currerntCityPartition = "all";
        salesPoints.renderFirstLetters(allCities, firstLettersContainer);
        salesPoints.renderCitiesAlphabetically(allCities, mainListContainer);
        salesPoints.setMapPoints(allCities);
        // Map
        clearMap();
        mapCities = allCities;
        ymaps.ready(initYandexMap);
        // Se target action for letters
        setLettersTargetAction();
    });

    function setLettersTargetAction() {
        let letterListBtns = document.querySelectorAll(
            ".addresses-nav__letters-btn"
        );
        for (let item of letterListBtns) {
            item.addEventListener("click", function () {
                let clickedLetter = item.innerHTML;
                renderLetterItems(clickedLetter);
                // Set as active
                for (let item of letterListBtns) {
                    item.classList.remove("is-active");
                }
                item.classList.add("is-active");
            });
        }
    }

    // Render cities by name on letter click
    function renderLetterItems(clickedLetter) {
        // Conditionallyy pass city array (large / all ciities)
        if (currerntCityPartition === "large") {
            let citiesByClickedLetter = salesPoints.getCitiesByLetter(
                largeCities,
                clickedLetter
            );
            salesPoints.renderCitiesAlphabetically(
                citiesByClickedLetter,
                mainListContainer
            );
            clearMap();
            mapCities = citiesByClickedLetter;
            ymaps.ready(initYandexMap);
        } else {
            let citiesByClickedLetter = salesPoints.getCitiesByLetter(
                allCities,
                clickedLetter
            );
            salesPoints.renderCitiesAlphabetically(
                citiesByClickedLetter,
                mainListContainer
            );
            clearMap();
            mapCities = citiesByClickedLetter;
            ymaps.ready(initYandexMap);
        }
    }

    // Yandex maps
    function initYandexMap() {
        if (mapDomElement) {
            // Create map
            var mainMap = null;
            mainMap = new ymaps.Map(
                mapDomElement,
                salesPoints.setMap(mapCities)
            );
            // Add points to map
            let geoPoints = salesPoints.setMapPoints(mapCities);
            for (let point of geoPoints) {
                let geoObject = new ymaps.GeoObject(point, {
                    preset: "islands#dotIcon",
                    iconColor: "hsla(206, 82%, 26%, 1)",
                });
                mainMap.geoObjects.add(geoObject);
                let link = "/sales/" + geoObject.properties._data.link;
                geoObject.events.add("click", function (e) {
                    location.href = link;
                });
            }
        }
    }

    function clearMap() {
        let mapDom = document.querySelector("ymaps");
        mapDom.remove();
    }
};

export default salesPointsUi;
