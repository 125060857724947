$('.main-nav__toggler').click(function(){
    $(this).toggleClass('active');
    $('.main-nav__content').fadeToggle();
});

priorityPlus(document.querySelector('.js-p-target'), {
    innerToggleTemplate: "Ещё",
});

$(window).scroll(function(){
    var headerMain = document.querySelector(".header__main")
    var headerMainRect = headerMain.getBoundingClientRect();

	if(headerMainRect.top <= 0) {
        headerMain.classList.add('scrolled');
    } else {
        headerMain.classList.remove('scrolled');
    }
});
