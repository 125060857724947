var swiperBanners = new Swiper('.about-index__features', {
	slidesPerView: 1,
	loop: true,
	autoplay: {
		delay: 6000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	watchOverflow: true,
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
	navigation: {
		nextEl: '.about-index__features-nav--next',
		prevEl: '.about-index__features-nav--prev'
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true
	}
});

$(".about-index__features").mouseenter(function() {
    swiperBanners.autoplay.stop();
});

$(".about-index__features").mouseleave(function() {
    swiperBanners.autoplay.start();
});

