// import { gsap } from "gsap";
import { formRegex } from "../_Common/const";

export default class ContentviewTrustManagmentOfPrivateInvestorsAssets {
    constructor() {
        console.log(
            `ContentviewTrustManagmentOfPrivateInvestorsAssets class is loaded`
        );
        // Form elements
        this.formMain = document.querySelector(`.jsFormMain`);
        this.formSuccess = document.querySelector(`.jsFormSuccess`);
        this.formMistakesContainer = document.querySelector(
            `.form__mistakes`
        );
        this.formMistakesMessage = document.querySelector(`.jsFormMistakes`);
        this.formMistakesCloseBtn = document.querySelector(
            `.jsFormMistakesCloseBtn`
        );
        this.nameInput = document.querySelector(`.jsNameInput`);
        this.phoneInput = document.querySelector(`.jsPhoneInput`);
        this.mailInput = document.querySelector(`.jsMailInput`);
        this.cityInput = document.querySelector(`.jsCityInput`);
        this.titleBlock = document.querySelector(`.trust-management-request__title`);
        this.fieldsBlock = document.querySelector(`.form__fields`);
        this.formTitle = document.querySelector(`.trust-management-request__form-title`);
        this.submitBtnNode = document.querySelector(`.jsSubmitBtn`);
        this.responseStatusMessageNode = document.querySelector(
            `.jsResponseStatus`
        );
        // Animation
        // this.mistakesTl = new gsap.timeline();
        // this.animationDuration = 0.15;
        // Event listeners
        if (this.nameInput) {
            this.nameInput.addEventListener(`input`, (e) => {
                this.handleNameInput(e);
            });
        }
        if (this.phoneInput) {
            this.phoneInput.addEventListener(`input`, (e) => {
                this.handlePhoneInput(e);
            });
        }
        if (this.mailInput) {
            this.mailInput.addEventListener(`input`, (e) => {
                this.handleMailInput(e);
            });
        }
        if (this.cityInput) {
            this.cityInput.addEventListener(`input`, (e) => {
                this.handleCityInput(e);
            });
        }
        if (this.formMain) {
            this.formMain.addEventListener(`submit`, (e) => {
                e.preventDefault();
                this.handleSubmit();
            });
        }

        // Kinda sorta state 😜
        this.nameIsValid = false;
        this.phoneIsValid = false;
        this.mailIsValid = false;
        this.cityIsValid = false;
    }

    // 🤩 🤩 🤩
    handleNameInput = (e) => {
        this.validateNameInput(e);
    };

    validateNameInput = (e) => {
        const val = e.target.value;
        if (!val.length) {
            this.nameInput.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.nameInput.classList.add(`jsIsEmpty`);
            this.nameIsValid = false;
        } else if (val.length >= 1) {
            this.nameInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.nameInput.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.nameIsValid = true;
        } else {
            this.nameInput.classList.remove(`success`, `jsSuccess`);
            this.nameInput.classList.add(`error`, `jsError`);
            this.nameIsValid = false;
        }
    };

    // 📱 📱 📱
    handlePhoneInput = (e) => {
        this.validatePhoneInput(e);
    };

    validatePhoneInput = (e) => {
        const val = e.target.value;
        if (!val.length) {
            this.phoneInput.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.phoneInput.classList.add(`jsIsEmpty`);
            this.phoneIsValid = false;
        } else if (
            val.length > 3
        ) {
            this.phoneInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.phoneInput.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.phoneIsValid = true;
        } else {
            this.phoneInput.classList.remove(`success`, `jsSuccess`);
            this.phoneInput.classList.add(`error`, `jsError`);
            this.phoneIsValid = false;
        }
    };

    // 💌 💌 💌
    handleMailInput = (e) => {
       this.validateMailInput(e);
    };

    validateMailInput = (e) => {
        this.handleMailErrorUpdate(e);
        const val = e.target.value;
        if (!val.length) {
            this.mailInput.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.mailInput.classList.add(`jsIsEmpty`);
            this.mailIsValid = false;
        } else if (val.length > 0 && val.match(formRegex.mailValidationRegex)) {
            this.mailInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.mailInput.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.mailIsValid = true;
        } else {
            this.mailInput.classList.remove(`success`, `jsSuccess`);
            this.mailInput.classList.add(`error`, `jsError`);
            this.mailIsValid = false;
        }
    };

    handleMailErrorUpdate = (e) => {
        const parent = e.target.closest(`.jsFormItem`);
        const errorDiv = parent.querySelector(`.form__item-mistake`);
        if (errorDiv) {
            errorDiv.innerText = this.handleEmailErrorMessage();
        }
    };

    // 🌇  🏙  🌉
    handleCityInput = (e) => {
        this.validateCityInput(e);
    };

    validateCityInput = (e) => {
        const val = e.target.value;
        if (!val.length) {
            this.cityInput.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.cityInput.classList.add(`jsIsEmpty`);
            this.cityIsValid = false;
        } else if (val.length >= 1) {
            this.cityInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.cityInput.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.cityIsValid = true;
        } else {
            this.cityInput.classList.remove(`success`, `jsSuccess`);
            this.cityInput.classList.add(`error`, `jsError`);
            this.cityIsValid = false;
        }
    };

    // Validate form
    validateForm = () => {
        if (
            this.nameIsValid &&
            this.phoneIsValid &&
            this.mailIsValid &&
            this.cityIsValid
        ) {
            return true;
        } else {
            this.hideAllMistakes();
            this.showUserMistakes();
            return false;
        }
    };

    showUserMistakes = () => {
        let invalidFields = document.querySelectorAll(`.jsError, .jsIsEmpty`);
        invalidFields.forEach((el) => {
            let errorDiv = document.createElement("div");
            errorDiv.classList.add(`form__item-mistake`);
            let errorString = ``;
            if (el.classList.contains(`jsNameInput`)) {
                errorString = `Укажите Ваши Имя и Фамилию`;
            } else if (el.classList.contains(`jsMailInput`)) {
                errorString = this.handleEmailErrorMessage();
            } else if (el.classList.contains(`jsPhoneInput`)) {
                errorString = `Укажите Ваш телефон`;
            } else if (el.classList.contains(`jsCityInput`)) {
                errorString = `Укажите Ваш город`;
            }
            errorDiv.innerHTML = errorString;
            el.insertAdjacentElement(`afterend`, errorDiv);
            el.classList.add(`error`);
        });
    };

    handleEmailErrorMessage = () => {
        const mailInput = this.mailInput.value;
        if (mailInput.length === 0) return `Укажите Ваш E-mail`;
        const marker = mailInput.indexOf(`@`);
        if (marker === -1) return `E-mail должен содержать символ «@»`;
        const substrings = mailInput.split(`@`);
        if (substrings[0].length === 0)
            return `Введите часть e-mail до символа «@»`;
        if (substrings[1].length === 0)
            return `Введите часть e-mail после символа «@»`;
        return `Продолжайте ввод e-mail`;
    };

    hideUserMistake = (e) => {
        const parent = e.target.closest(`.jsFormItem`);
        const errorDiv = parent.querySelector(`.form__item-mistake`);
        if (errorDiv) errorDiv.remove();
    };

    hideAllMistakes = () => {
        const errorElements = document.querySelectorAll(`.jsError, .jsIsEmpty`);

        errorElements.forEach((el) => {
            const parent = el.closest(`.jsFormItem`);
            const errorDiv = parent.querySelector(`.form__item-mistake`);
            if (errorDiv) errorDiv.remove();
        });
    };

    handleSubmit = () => {
        if (this.validateForm()) {
            console.log(`Networking`);
            // this.sendLikeIts1979Again();
            this.sendViaFetch();
        }
    };

    sendLikeIts1979Again = () => {
        const url = "/send-request-purchase-du/";
        const data = {
            name: this.nameInput.value,
            phone: this.phoneInput.value,
            email: this.mailInput.value,
            city: this.cityInput.value,
        };

        const dataString = JSON.stringify(data);
        const request = new XMLHttpRequest();
        request.open("POST", url);
        // request.setRequestHeader(`Content-Type`, `application/json`);
        request.setRequestHeader(`x-requested-with`, `XMLHttpRequest`);
        request.send(dataString);

        console.log(data);
        console.log(dataString);

        request.onload = () => {
            let res = null;
            try {
                res = JSON.parse(request.responseText);
            } catch (e) {
                console.log(`Can't parse json`);
            }
            if (res) {
                this.handleResponse(res);
            }
        };

        request.onerror = () => {
            this.showSubmitFail();
        };
    };

    sendViaFetch = async () => {
        console.log(`Sending via fetch`);
        const url = "/send-request-purchase-du/";
        const data = {
            name: this.nameInput.value,
            phone: this.phoneInput.value,
            email: this.mailInput.value,
            city: this.cityInput.value,
        };
        const dataString = JSON.stringify(data);
        let response = await fetch(url, {
            method: "POST",
            // mode: "cors",
            // withCredentials: true,
            // credentials: "include",
            // credentials: "same-origin",
            headers: {
                "x-requested-with": "XMLHttpRequest",
                "Content-Type": "application/json"
            },
            body: dataString,
        });

        if (response.ok) {
            console.log(`Response OK`);
            try {
                let responseJson = await response.json();
                this.handleResponse(responseJson);
            } catch (err) {
                console.error("Error parsing json from " + url + "\n" + err);
                this.showSubmitFail();
            }
        } else {
            console.error("http error while getting url: " + response.status);
            this.showSubmitFail();
        }
    };

    handleResponse = (responseJson) => {
        console.log(`Handling response`);
        this.showSubmitSuccess();
    };

    showSubmitSuccess = () => {
        console.log(`Show submit success`);
        if (this.submitBtnNode) {
            this.submitBtnNode.style.cssText = `pointer-events: none; opacity: 0.5; background-color: #C4C4C4; border-color: #C4C4C4; color: #FFFFFF`;
            this.submitBtnNode.innerText = `Запрос отправлен`;
            this.formTitle.innerText = `Наши специалисты ответят в ближайшее время`;

            this.titleBlock.innerText = `Спасибо! Ваш запрос принят.`;
            this.fieldsBlock.innerHTML = ``;
        }

        
    };

    showSubmitFail = () => {
        console.log(`Showing submit fail`);
        if (this.responseStatusMessageNode) {
            this.responseStatusMessageNode.innerHTML = ``;
            this.responseStatusMessageNode.innerHTML = `Произошла ошибка`;
        }
    };
}
