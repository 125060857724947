import CityInfo from "./CityInfo";

const salesCityInfoUi = function(cityList) {
    let cityInfo = new CityInfo(cityList);

    cityInfo.renderCityName();

    //Get Data
    let cityLocations = cityInfo.getCityLocations();
    console.log(cityLocations);
    // Cities to display on the map
    let mapSalesPoints = cityLocations;
    // DOM elements
    const infoContainer = document.querySelector("#js_cityInfoContainer");
    const selectorsContainer = document.querySelector("#js_selectorsContainer");
    const mapDomElement = document.querySelector("#js_yMap");

    // Render initial state
    cityInfo.renderLocations(infoContainer, cityLocations);
    createStationSelector();
    createAgentsSelector();
    ymaps.ready(initYandexMap);

    // Create selectorz
    // Station 🚟💥🚅
    function createStationSelector() {
        let stations = cityInfo.getCityStations();
        if (stations.length > 1) {
            let selector = document.createElement("select");
            selector.classList.add("select", "city-addresses__nav-select");
            selector.id = "js_stationSelector";
            let defaultOption = document.createElement("option");
            defaultOption.value = "default";
            defaultOption.innerHTML = "Все станции";
            selector.append(defaultOption);
            for (let station of stations) {
                let option = document.createElement("option");
                option.value = station.id;
                option.innerHTML = station.name;
                selector.append(option);
            }
            selector.addEventListener("change", function() {
                handleStationSelection(selector.value);
            });
            selectorsContainer.append(selector);
        }
    }

    function handleStationSelection(stationId) {
        if (document.querySelector("#js_agentSelector")) {
            document.querySelector("#js_agentSelector").value = "default";
        }
        if (stationId === "default") {
            cityInfo.renderLocations(infoContainer, cityLocations);
            // Map
            clearMap();
            mapSalesPoints = cityLocations;
            ymaps.ready(initYandexMap);
        } else {
            let stationLocations = cityInfo.getStationLocations(stationId);
            cityInfo.renderLocations(infoContainer, stationLocations);
            // Map
            clearMap();
            mapSalesPoints = stationLocations;
            ymaps.ready(initYandexMap);
        }
    }

    // Agent 🔫🔫🔫
    function createAgentsSelector() {
        let agents = cityInfo.getLocationAgents(cityLocations);
        if (agents.length > 1) {
            let selector = document.createElement("select");
            selector.classList.add("select", "city-addresses__nav-select");
            selector.id = "js_agentSelector";
            let defaultOption = document.createElement("option");
            defaultOption.value = "default";
            defaultOption.innerHTML = "Все агенты";
            selector.append(defaultOption);
            for (let agent of agents) {
                let option = document.createElement("option");
                option.value = agent.id;
                option.innerHTML = agent.name;
                selector.append(option);
            }
            selector.addEventListener("change", function() {
                handleAgentSelection(selector.value);
            });
            selectorsContainer.append(selector);
        }
    }

    function handleAgentSelection(agentId) {
        if (document.querySelector("#js_stationSelector")) {
            document.querySelector("#js_stationSelector").value = "default";
        }
        if (agentId === "default") {
            cityInfo.renderLocations(infoContainer, cityLocations);
            // Map
            clearMap();
            mapSalesPoints = cityLocations;
            ymaps.ready(initYandexMap);
        } else {
            let agents = cityInfo.getLocationAgents(cityLocations);
            let selectedAgent = agents.find(a => a.id === agentId);
            let agentLocations = cityInfo.getAgentLocations(
                selectedAgent,
                cityLocations
            );
            cityInfo.renderLocations(infoContainer, agentLocations);
            // Map
            clearMap();
            mapSalesPoints = agentLocations;
            ymaps.ready(initYandexMap);
        }
    }

    // Yandex maps
    function initYandexMap() {
        if (mapDomElement) {
            // Create map
            var mainMap = null;
            mainMap = new ymaps.Map(
                mapDomElement,
                cityInfo.setMap(mapSalesPoints)
            );
            // Add points to map
            let geoPoints = cityInfo.setMapPoints(mapSalesPoints);
            for (let point of geoPoints) {
                let geoObject = new ymaps.GeoObject(point, {
                    preset: "islands#dotIcon",
                    iconColor: "hsla(206, 82%, 26%, 1)"
                });
                mainMap.geoObjects.add(geoObject);
                console.log("add point" + point);
            }
        }
    }

    function clearMap() {
        console.log("clearing map");
        let mapDom = document.querySelector("ymaps");
        mapDom.remove();
    }
};

export default salesCityInfoUi;
