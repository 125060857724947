class CityList {
    constructor(allCities) {
        this.allCities = allCities;
    }

    // List methods
    getLargeCities() {
        let largeCities = this.allCities.filter(c => c.isLarge === true);
        return largeCities;
    }

    getSmallCities() {
        let smallCities = this.allCities.filter(c => c.isLarge === false);
        return smallCities;
    }

    getAllCities() {
        return this.allCities;
    }

    getFirstLetters(cities) {
        let letters = [];
        for (let city of cities) {
            let firstLetter = city.name.charAt(0);
            letters.push(firstLetter);
        }
        let uniqueLetters = [...new Set(letters)];
        uniqueLetters.sort();
        return uniqueLetters;
    }

    renderFirstLetters(cities, domContainer) {
        domContainer.innerHTML = "";
        let firstLetters = this.getFirstLetters(cities);
        for (let letter of firstLetters) {
            let li = document.createElement("li");
            let button = document.createElement("button");
            li.classList.add("addresses-nav__letters-item");
            domContainer.append(li);
            button.classList.add("addresses-nav__letters-btn");
            button.innerHTML = letter;
            li.append(button);
        }
    }

    getCitiesByLetter(cities, letter) {
        let citiesByLetter = cities.filter(c => c.name.charAt(0) === letter);
        let uniqueCitiesByLetter = this.removeDuplicates(citiesByLetter, "id");
        return uniqueCitiesByLetter;
    }

    renderCitiesAlphabetically(cities, domContainer) {
        domContainer.innerHTML = "";
        let firstLetters = this.getFirstLetters(cities);
        let mainList = document.createElement("ul");
        mainList.classList.add("addresses-table__list")
        for (let letter of firstLetters) {
            let mainListItem = document.createElement("li");
            mainListItem.classList.add("addresses-table__item")
            let mainListItemHeader = document.createElement("h3");
            mainListItemHeader.classList.add("addresses-table__item-title");
            mainListItemHeader.innerHTML = letter;
            mainListItem.append(mainListItemHeader);
            let subList = document.createElement("ul");
            subList.classList.add("addresses-table__sublist")
            mainListItem.append(subList);
            let citiesByLetter = this.getCitiesByLetter(cities, letter);
            for (let city of citiesByLetter) {
                let subListItem = document.createElement("li");
                subListItem.classList.add("addresses-table__subitem")
                let sublistLink = document.createElement("a");
                sublistLink.classList.add("addresses-table__subitem-link");
                sublistLink.href = "" + city.id + "/";
                sublistLink.innerHTML = city.name;
                // sublistLink.addEventListener("click", () =>
                //     this.gotoCityPage(city.name)
                // );
                subListItem.append(sublistLink);
                subList.append(subListItem);
            }
            mainList.append(mainListItem);
        }
        domContainer.append(mainList);
    }

    // gotoCityPage(cityName) {
    //     let city = this.allCities.find(c => c.name === cityName);
    //     let cityId = city.id;
    //     window.location.href = cityId;
    // }

    // Map methods
    setMap(cities) {
        // Define vars
        let map = {};
        let allLatitudes = [];
        let allLongitudes = [];

        // Calculate medium lattitude of map center
        for (let city of cities) {
            let lattitude = city.cityCoords[0];
            allLatitudes.push(lattitude);
        }
        let medianLattitude =
            allLatitudes.reduce((a, b) => a + b, 0) / allLatitudes.length;

        // Calculate medium longitude of map center
        for (let city of cities) {
            let longitude = city.cityCoords[1];
            allLongitudes.push(longitude);
        }
        let medianLongitude =
            allLongitudes.reduce((a, b) => a + b, 0) / allLongitudes.length;

        // Set map parameters
        map.center = [medianLattitude, medianLongitude];
        map.zoom = 3;
        map.controls = [];

        return map;
    }

    setMapPoints(cities) {
        // Define var
        let mapPoints = [];
        // Create points
        for (let city of cities) {
            let geoPoint = {
                geometry: {
                    type: "Point",
                    coordinates: city.cityCoords
                },
                properties: {
                    // iconCaption: city.name
                    iconCaption: null,
                    hintContent: city.name,
                    link: city.id
                }
            };
            mapPoints.push(geoPoint);
        }

        return mapPoints;
    }

    // Utility functions
    removeDuplicates(array, propName) {
        return array.filter((obj, pos, arr) => {
            return (
                arr.map(mapObj => mapObj[propName]).indexOf(obj[propName]) ===
                pos
            );
        });
    }
}

export default CityList;
