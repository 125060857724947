$(document).ready(function() {
    function showPopupSubscription() {
        if (!getcookie("subscribe_showed")) {
            $(".popup-subscription").fadeIn();
            setcookie("subscribe_showed", 1, 4 * 12 * 60 * 60 * 24, "/");
        }
    }

    function setcookie(name, value, expires, path, domain, secure) { 
        expires instanceof Date ? expires = expires.toGMTString() : typeof(expires) == "number" && (expires = (new Date(+(new Date) + expires * 1e3)).toGMTString());
        var r = [name + "=" + escape(value)], s, i;
        for(i in s = {expires: expires, path: path, domain: domain}){
            s[i] && r.push(i + "=" + s[i]);
        }
        return secure && r.push("secure"), document.cookie = r.join(";"), true;
    }

    function getcookie(name) {
        var matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined; 
    }

    setTimeout(showPopupSubscription, 10000);

    $(".popup-subscription__close, .popup-subscription__button-close").click(function(e) {
        $(".popup-subscription").fadeOut();
    });

    $(document).on("submit", "form.popup-subscription__form", function (e) {
        e.preventDefault();
        var action = $(this).attr("action");
        var query = $(this).serialize();
        query = "ajax=1&" + query;
        var method = $(this).attr("method");
        var url = action;
        $.ajax(url, {
            method: method,
            data: query,
            complete: function (data) {
                if (data.status === 200 && !data.responseJSON.status) {
                    $(".popup-subscription__form-main, .popup-subscription__text").hide();
                    $(".popup-subscription__form-sucess, .popup-subscription__button-close").show();
                } else {
                    if (data.responseJSON && data.responseJSON.errors && data.responseJSON.errors.email) {
                        $(".popup-subscription__form-mistake").html("<p>Пожалуйста, заполните поле</p>");
                    } else {
                        $(".popup-subscription__form-mistake").html("<p>Произошла ошибка, попробуйте позднее</p>");
                    }
                }
            },
        });
    });
});
