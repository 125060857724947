$(document).ready(function() {
    $(".feedback-question__btn").click(function(){
        $("body").addClass("no-scroll");
        $(".popup-question").addClass("active");
    });

    $(document).on("submit", "form.feedback-subscription__form", function (e) {
        e.preventDefault();
        var action = $(this).attr("action");
        var query = $(this).serialize();
        query = "ajax=1&" + query;
        var method = $(this).attr("method");
        var url = action;
        $.ajax(url, {
            method: method,
            data: query,
            complete: function (data) {
                if (data.status === 200 && !data.responseJSON.status) {
                    $(".feedback-subscription__form-main").hide();
                    $(".feedback-subscription__form-sucess").show();
                } else {
                    if (data.responseJSON && data.responseJSON.errors && data.responseJSON.errors.email) {
                        $(".feedback-subscription__form-mistake").html("<p>Пожалуйста, заполните поле</p>");
                    } else {
                        $(".feedback-subscription__form-mistake").html("<p>Произошла ошибка, попробуйте позднее</p>");
                    }
                }
            },
        });
    });
});
