$(document).ready(function() {
    var isVisible = window.popupMessage.isVisible;
    var messageVersion = +window.popupMessage.messageVersion;
    var cookieName = "cookies-message-hide";
    var cookieValue = encodeURIComponent(messageVersion);
    var cookieFullValue = cookieValue + "; path=/; max-age=2592000";
    var messageVersionInCookie = +getCookie(cookieName);
    if (isVisible && (!messageVersionInCookie || messageVersionInCookie < messageVersion)) {
        $("body").addClass("no-scroll");
        $(".popup-message").addClass("active");
    }

    $(".popup-message__no-show").on("click", function(e) {
        document.cookie = encodeURIComponent(cookieName) + "=" + cookieFullValue;
        $("body").removeClass("no-scroll");
        $(".popup-message").removeClass("active");
    });

    $(".popup-message__info a").on("click", function(e) {
        document.cookie = encodeURIComponent(cookieName) + "=" + cookieFullValue;
    });
});
