const submitQuestionForm = (p) => {
    fetch("/send-request/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        },
        body: $(p).serialize()
    })
        .then(response => response.json())
        .then(json => {
            if (json.errors && json.errors.length) {
                $('div.form__item-mistake').hide().text('');

                Object.keys(json.data).map(k => {
                    $('.agreement__label').removeClass('has-error');
                    $('input[name="question[' + k + ']"]').val(json.data[k]);
                });

                Object.keys(json.errors).map(k => {
                    $('div.question_' + k).text(json.errors[k]).show();
                    if(k == 'confirm') $('.agreement__label').addClass('has-error');
                });
            }else{
                $('.popup-question__teaser').hide();
                $('form.ajax-submit-form').replaceWith('<div class="popup-question__success"><div class="popup-question__success-title">Ваше обращение отправлено.</div></div>');
            }
        });

}

const onClickReCaptcha = (response) => {
    fetch("/reCaptchaCheck/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ response })
    })
        .then(response => response.json())
        .then(json => {
            if (json.success === true) {
                $(".g-recaptcha").hide();
                $('form.ajax-submit-form').show();
            }
        });
}
export { onClickReCaptcha, submitQuestionForm };

if (window.qq) {
    let passportUploader = new qq.FineUploader({
        element: document.getElementById("passport-file-uploader"),
        classes: {
            success: "file-load__result-item--success",
            fail: "file-load__result-item--error"
        },
        template: "qq-simple-thumbnails-template",
        request: {
            endpoint: "/request-files/user-attachments/"
        },
        thumbnails: {
            placeholders: {
                waitingPath: "/img/fine-uploader/placeholders/waiting-generic.png",
                notAvailablePath: "/img/fine-uploader/placeholders/not_available-generic.png"
            }
        },
        autoUpload: true,
        debug: true,
        session: {
            endpoint: "/request-files/user-attachments/"
        },
        deleteFile: {
            enabled: true,
            endpoint: "/request-files/user-attachments"
        },
        callbacks: {
            onStatusChange: (id, oldStatus, newStatus) => {
                $("#passport-file-uploader-counter").val(passportUploader.getNetUploads());
            }
        },
        text: {
            failUpload: "Расширение приложенного файла не поддерживается, либо превышен максимальный размер файла",
            failLimitCountFilesUpload: "Слишком много файлов. Максимальное количество файлов для загрузки 10 шт.",
            failLimitTotalSizeUpload: "Превышен максимальный размер загружаемых файлов",
            blockedIp: "В целях безопасности, ваша учётная запись временно заблокирована на 5 мин. Повторите попытку позднее.",
            waitingForResponse: "Загрузка...",
        }
    });
    $("#passport-file-uploader-counter").val(passportUploader.getNetUploads());
    $("#upload-btn").on("change", function (event) {
        passportUploader.addFiles(event.target);
    });

    //- {{#errors.attachments}}
    //- $("#passport-file-uploader .qq-upload-list-selector").append($("#errorItem").html());
    //- $(".qq-close-error").on("click", function() {
    //-     $(this).parents("li").hide();
    //- });
    //- {{/errors.attachments}}


}

$(document).ready(function() {
    if (window.location.hash === "#q-modal") {
        $("body").addClass("no-scroll");
        $("#q-modal").addClass("active");
    }
});
