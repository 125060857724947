const lastAsideBlock = document.querySelector('.with-aside .aside__block:last-of-type');
function asideSticky() {
    var lastAsideBlockHeight = lastAsideBlock.clientHeight;
    if (lastAsideBlockHeight < document.documentElement.clientHeight - 104) {
        lastAsideBlock.classList.add("aside__block--sticky")
    } else {
        lastAsideBlock.classList.remove("aside__block--sticky")
    }
}

if (lastAsideBlock) {
    asideSticky()

    window.addEventListener('resize', function(event){
        asideSticky()
    });
}
