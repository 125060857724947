$('.profitability-table__toggler--1').click(function(){
    $('html, body').animate( { scrollTop: $(".trust-management-strategies__item:nth-of-type(1)").offset().top - $(".header").outerHeight() }, 1200 );
    if(!$(".trust-management-strategies__item:nth-of-type(1) .trust-management-strategies__item-toggler").hasClass("active")){
        $(".trust-management-strategies__item:nth-of-type(1) .trust-management-strategies__item-toggler").addClass("active");
        $(".trust-management-strategies__item:nth-of-type(1) .trust-management-strategies__item-content").slideDown();
    }
});

$('.profitability-table__toggler--2').click(function(){
    $('html, body').animate( { scrollTop: $(".trust-management-strategies__item:nth-of-type(3)").offset().top - $(".header").outerHeight() }, 1200 );
    if(!$(".trust-management-strategies__item:nth-of-type(3) .trust-management-strategies__item-toggler").hasClass("active")){
        $(".trust-management-strategies__item:nth-of-type(3) .trust-management-strategies__item-toggler").addClass("active");
        $(".trust-management-strategies__item:nth-of-type(3) .trust-management-strategies__item-content").slideDown();
    }
});

$('.profitability-table__toggler--3').click(function(){
    $('html, body').animate( { scrollTop: $(".trust-management-strategies__item:nth-of-type(4)").offset().top - $(".header").outerHeight() }, 1200 );
    if(!$(".trust-management-strategies__item:nth-of-type(4) .trust-management-strategies__item-toggler").hasClass("active")){
        $(".trust-management-strategies__item:nth-of-type(4) .trust-management-strategies__item-toggler").addClass("active");
        $(".trust-management-strategies__item:nth-of-type(4) .trust-management-strategies__item-content").slideDown();
    }
});

$('.profitability-table__toggler--4').click(function(){
    $('html, body').animate( { scrollTop: $(".trust-management-strategies__item:nth-of-type(6)").offset().top - $(".header").outerHeight() }, 1200 );
    if(!$(".trust-management-strategies__item:nth-of-type(6) .trust-management-strategies__item-toggler").hasClass("active")){
        $(".trust-management-strategies__item:nth-of-type(6) .trust-management-strategies__item-toggler").addClass("active");
        $(".trust-management-strategies__item:nth-of-type(6) .trust-management-strategies__item-content").slideDown();
    }
});

$('.trust-management-strategies__item-toggler').click(function(){
    $(this).toggleClass('active');
    $(this).parent().siblings('.trust-management-strategies__item-content').slideToggle();
});
