$('.donut__segment').hover(function () {
    $(this).toggleClass('active');
	$('.fund-diagram__legends-item').eq($(this).index()).toggleClass('active');
});

$('.fund-diagram__legends-item').hover(function () {
    $(this).toggleClass('active');
	$('.donut__segment').eq($(this).index()).toggleClass('active');
});

if ($(".fund-description__files-table tbody tr").length > 2) {
    $(".fund-description__files-more").show();
    $(".fund-description__files-table tbody tr:nth-of-type(n + 3)").hide();
    $(".fund-description__files-table").addClass("with-hidden");

    $(".fund-description__files-more").click(function () {
        $(this).text(function(i, v){
            return v === 'Показать все документы' ? 'Скрыть' : 'Показать все документы'
        });
        $(".fund-description__files-table tbody tr:nth-of-type(n + 3)").toggle();
        $(".fund-description__files-table").toggleClass("with-hidden");
    });
}
