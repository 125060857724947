class CityInfo {
    constructor(allCities) {
        this.allCities = allCities;
    }

    getCityIdFromUrl() {
        let pathname = window.location.pathname.replace(/\/{1,}$/g, "");
        let charIndex = pathname.lastIndexOf("/");
        let cityId = pathname.substr(charIndex + 1);
        return cityId;
    }

    getCityLocations() {
        let cityId = this.getCityIdFromUrl();
        let allCities = this.allCities;
        let cityLocations = allCities.filter((c) => c.id === cityId);
        return cityLocations;
    }

    getCityName = () => {
        const cityId = this.getCityIdFromUrl();
        const allCities = this.allCities;
        const city = allCities.find((c) => c.id === cityId);
        if (!city) {
            return this.goTo404();
        }
        return city.name;
    };

    goTo404 = () => {
        window.location.href = "/404/";
    };

    getCityStations() {
        let cityLocations = this.getCityLocations();
        let stations = [];
        for (let location of cityLocations) {
            if (location.stationId != null && location.stationName != null) {
                let station = {
                    id: location.stationId,
                    name: location.stationName,
                };
                stations.push(station);
            }
        }
        // let uniqueStations = [...new Set(stations)];
        let uniqueStations = this.removeDuplicates(stations, "id");
        return uniqueStations;
    }

    getLocationAgents(locations) {
        let agents = [];
        for (let location of locations) {
            let agent = {
                name: String,
                id: String,
            };
            agent.name = location.agentName;
            agent.id = location.agentId;
            agents.push(agent);
        }
        // let uniqueAgents = [...new Set(agents)];
        let uniqueAgents = this.removeDuplicates(agents, "id");
        return uniqueAgents;
    }

    getAgentLocations(agent, locations) {
        let agentLocations = locations.filter((l) => l.agentId === agent.id);
        return agentLocations;
    }

    getStationLocations(stationId) {
        let cityLocations = this.getCityLocations();
        let stationLocations = cityLocations.filter(
            (l) => l.stationId === stationId
        );
        return stationLocations;
    }

    renderCityName = () => {
        const container = document.querySelector("#jsCityName");
        container.innerHTML = this.getCityName();
        console.log(this.getCityName());
    };

    renderLocations(domContainer, locations) {
        domContainer.innerHTML = "";
        let agents = this.getLocationAgents(locations);
        let mainList = document.createElement("ul");
        mainList.classList.add("city-points__list");
        for (let agent of agents) {
            let mainListItem = document.createElement("li");
            mainListItem.classList.add("city-points__item")
            let mainListItemTitle = document.createElement("h3");
            mainListItemTitle.classList.add("city-points__item-title")
            mainListItemTitle.innerHTML = agent.name;
            mainListItem.append(mainListItemTitle);
            let subList = document.createElement("ul");
            subList.classList.add("city-points__sublist");
            let agentLocations = this.getAgentLocations(agent, locations);
            for (let location of agentLocations) {
                // Render location
                let subListItem = document.createElement("li");
                subListItem.classList.add("city-points__subitem");
                let subListContent = document.createElement("div");
                subListContent.classList.add("city-points__subitem-info")
                // Render address
                let adressContainer = document.createElement("p");
                adressContainer.innerHTML = location.adress;
                adressContainer.classList.add("city-points__subitem-info-address")
                subListContent.append(adressContainer);
                // Render station
                if (location.stationId != null) {
                    let stationContainer = document.createElement("p");
                    stationContainer.innerHTML =
                        "Станция метро: " + location.stationName;
                    subListContent.append(stationContainer);
                }
                // Render phones
                if (location.phones.length) {
                    let phonesContainer = document.createElement("p");
                    let phoneString = null;
                    if (location.phones.length > 1) {
                        phoneString = "Телефоны: ";
                    } else {
                        phoneString = "Телефон: ";
                    }
                    for (let phone of location.phones) {
                        phoneString += phone + ", ";
                    }
                    let trimIndex = phoneString.lastIndexOf(",");
                    phoneString = phoneString.substr(0, trimIndex);
                    phonesContainer.innerHTML = phoneString;
                    subListContent.append(phonesContainer);
                }
                // Render shchedule
                if (location.schedule.length) {
                    location.schedule = location.schedule.filter(item => item.replace(/\s*/, "") != "");
                }
                if (location.schedule.length) {
                    let scheduleContainer = document.createElement("div");
                    scheduleContainer.classList.add("city-points__subitem-schedule");
                    let scheduleStringContainer = document.createElement("div");
                    scheduleStringContainer.classList.add("city-points__subitem-schedule-title");
                    scheduleStringContainer.innerHTML = "Режим работы:";
                    scheduleContainer.append(scheduleStringContainer);
                    let scheduleList = document.createElement("ul");
                    scheduleList.classList.add("city-points__subitem-schedule-list");
                    for (let scheduleItem of location.schedule) {
                        let scheduleListItem = document.createElement("li");
                        scheduleListItem.classList.add("city-points__subitem-schedule-item");
                        scheduleListItem.innerHTML = scheduleItem;
                        scheduleList.append(scheduleListItem);
                    }
                    scheduleContainer.append(scheduleList);
                    subListContent.append(scheduleContainer);
                }
                // Render comment
                if (location.comment != null) {
                    let commentContainer = document.createElement("div");
                    commentContainer.className = "city-points__subitem-footnote";
                    commentContainer.innerHTML =
                        "Примечание: " + location.comment;
                    subListContent.append(commentContainer);
                }

                subListItem.append(subListContent);
                subList.append(subListItem);
            }
            mainListItem.append(subList);
            mainList.append(mainListItem);
        }
        domContainer.append(mainList);
    }

    // Map methods
    setMap(locations) {
        // Define vars
        let map = {};
        let allLatitudes = [];
        let allLongitudes = [];

        // Calculate medium lattitude of map center
        for (let location of locations) {
            let lattitude = location.locationCoords[0];
            allLatitudes.push(lattitude);
        }
        let medianLattitude =
            allLatitudes.reduce((a, b) => a + b, 0) / allLatitudes.length;

        // Calculate medium longitude of map center
        for (let location of locations) {
            let longitude = location.locationCoords[1];
            allLongitudes.push(longitude);
        }
        let medianLongitude =
            allLongitudes.reduce((a, b) => a + b, 0) / allLongitudes.length;

        // Set map parameters
        map.center = [medianLattitude, medianLongitude];
        map.zoom = 10;
        map.controls = [];

        return map;
    }

    setMapPoints(locations) {
        // Define var
        let mapPoints = [];
        // Create points
        for (let location of locations) {
            let geoPoint = {
                geometry: {
                    type: "Point",
                    coordinates: location.locationCoords,
                },
                properties: {
                    // iconCaption: location.agentName,
                    hintContent: location.agentName + "<br>" + location.adress,
                },
                options: {
                    preset: "islands#icon",
                    iconColor: "blue",
                },
            };
            mapPoints.push(geoPoint);
        }

        return mapPoints;
    }

    // Utility functions
    removeDuplicates(array, propName) {
        return array.filter((obj, pos, arr) => {
            return (
                arr.map((mapObj) => mapObj[propName]).indexOf(obj[propName]) ===
                pos
            );
        });
    }
}

export default CityInfo;
