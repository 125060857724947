$('.qa-table__toggler').click(function(){
    $(this).toggleClass('active');
    $(this).siblings('.qa-table__answer').slideToggle();
})

if (window.location.hash == '#how-to-buy'){
    $('a[name="how-to-buy"]').closest('.qa-table__toggler').addClass('active');
    $('a[name="how-to-buy"]').closest('.qa-table__toggler').siblings('.qa-table__answer').show();
}

$('a[href="/questions-and-answers/#how-to-buy"]').click(function(){
    $('a[name="how-to-buy"]').closest('.qa-table__toggler').addClass('active');
    $('a[name="how-to-buy"]').closest('.qa-table__toggler').siblings('.qa-table__answer').show();
})
