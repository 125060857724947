$("input[id^='record'][id$='show']").change(function() {
    if ($(this).is(':checked')) {
        $(this).parents("tr").nextAll(".operation-records__data:lt(1)").addClass("show-data");
        $(this).parents("td").find(".operation-records__unlock-input").addClass("show-data");
    } else {
        $(this).parents("tr").nextAll(".operation-records__data:lt(1)").removeClass("show-data");
        $(this).parents("td").find(".operation-records__unlock-input").removeClass("show-data");
        $(this).parents("td").find('.operation-records__unlock-input input').prop('checked', false).trigger('change');
    }
});

$("input[id^='record'][id$='edit']").change(function() {
    if ($(this).is(':checked')) {
        $(this).parents("tr").nextAll(".operation-records__data:lt(1)").find(".operation-records__changes-col-edit").addClass('unlocked');
        $(this).parents("tr").next().find(".operation-records__item .form__item").addClass('unlocked');
        $(this).parents("td").find(".operation-records__delete-input").addClass("show-data");
    } else {
        $(this).parents("tr").nextAll(".operation-records__data:lt(1)").find(".operation-records__changes-col-edit").removeClass('unlocked');
        $(this).parents("tr").next().find(".operation-records__item .form__item").removeClass('unlocked');
        $(this).parents("td").find(".operation-records__delete-input").removeClass("show-data");
        $(this).parents("td").find('.operation-records__delete-input input').prop('checked', false).trigger('change');
    }
});
