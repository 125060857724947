$(document).ready(function() {
    var cookieName = "cookies-notification-hide";
    var cookieValue = encodeURIComponent("1");
    var cookieFullValue = cookieValue + "; path=/; max-age=604800";
    if (document.cookie.indexOf(cookieName) === -1) {
        $(".popup-cookie").show();
    }

    $(".popup-cookie__close").click(function(e) {
        document.cookie = encodeURIComponent(cookieName) + "=" + cookieFullValue;
        $(".popup-cookie").hide();
    });
});
