let currencyTabs = function() {
    // Define vars
    // let currencyNavItems = document.querySelectorAll(".CurrerncyTabsNav li");
    // let currencySubviews = document.querySelectorAll(".js_currency-subview");
    let currencies = ["rub"];
    if (chartData['usd']) {
        currencies.unshift("usd");
    }

    // initView();
    //handleClick();

    renderCurrencyChart(0);
    if (chartData['usd'])
        renderCurrencyChart(1);

    // function initView() {
    //     renderCurrencyChart(0);
    //     renderCurrencyChart(1);
    // }

    // function handleClick() {
    //     for (let i = 0, length = currencyNavItems.length; i < length; i++) {
    //         currencyNavItems[i].onclick = function(){
    //             clearActiveMenuItem();
    //             currencyNavItems[i].classList.add("is-active", "js_isActive");
    //             hideAllSubviews();
    //             $(currencySubviews[i]).fadeIn(600);
    //             //renderCurrencyChart();
    //             setCurrencyCookie(i);
    //             renderCurrencyChart(i);
    //         };
    //     }
    // }

    // function clearActiveMenuItem() {
    //     for (let i = 0, length = currencyNavItems.length; i < length; i++) {
    //         currencyNavItems[i].classList.remove("is-active", "js_isActive");
    //     }
    // }

    // function hideAllSubviews() {
    //     for (let i = 0, length = currencySubviews.length; i < length; i++) $(currencySubviews[i]).hide();
    // }

    function renderCurrencyChart(index) {
        let currency = currencies[index];
        renderChart("chartdiv-" + currency, chartData[currency], currency);
    }

    let drawed = false;
    $('.fund-results__tabs-nav-btn').click(function(){
        if (!drawed)
        {
            drawed = true;
            renderCurrencyChart($(this).index());
        }
    });

    // function setCurrencyCookie(index) {
    //     var date = new Date(new Date().getTime() + 24 * 60 * 1000);
    //     document.cookie = "currency=" + currencies[index] + "; path=/; expires=" + date.toUTCString();
    // }

    // Hide active subview

    // Show subview
};

export default currencyTabs;
