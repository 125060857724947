var fundsSlider = new Swiper('.funds-slider__container', {
    slidesPerView: 'auto',
    spaceBetween: 16,
    centeredSlides: true,
	watchOverflow: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
	navigation: {
	    prevEl: '.funds-slider__navigation-button--prev',
	    nextEl: '.funds-slider__navigation-button--next'
	},
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 24,
            centeredSlides: false,
        },
        1360: {
            slidesPerView: 2,
            spaceBetween: 32,
            centeredSlides: false,
        }
    }
});
